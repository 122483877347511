<template>

    <auth-content>
      <el-header :title="$t(`modules.process.${currentType}.templates.title`)" slot="header">
        <div slot="actions">
          <el-header-action
            :disabled="loading"
            size="xs"
            icon="plus3"
            :label="$t(`modules.process.${currentType}.templates.button_text_add`)"
            @click.native="createContent()"
            v-if="userHasPermission('process', 'manage_module')"
          />
        </div>
      </el-header>

      <el-filters :filters="filters"  v-if="processTemplates && processTemplates.length > 1" />

      <div v-if="processTemplates && processTemplates.length > 0">
        <el-table
          :total="Object.keys(processTemplates).length"
          :columns="columns"
          :data="contentFiltered"
        >

          <div slot="actions" slot-scope="{row}">
            <el-table-action
              @click.prevent.native="$router.push({name: currentType + '.template.page', params: { world: $route.params.world, template: row.id } })"
              :icon="'icon-' + $router.resolve({name: currentType + '.template.page'}).route.meta.icon"
              :title="$t(`modules.process.${currentType}.templates.actions.edit`)"
              v-if="userHasPermission('process', 'manage_module')"
            />
            <el-table-action
              @click.prevent.native="$router.push({name: currentType + '.template.results', params: { world: $route.params.world, template: row.id } })"
              :icon="'icon-' + $router.resolve({name: currentType + '.template.results'}).route.meta.icon"
              :title="$t(`modules.process.${currentType}.templates.actions.results`)"
              v-if="userHasPermission('process', 'manage_module')"
            />
            <el-table-action
              @click.prevent.native="useTemplate(row)"
              :icon="'icon-' + $router.resolve({name: currentType + '.template.index'}).route.meta.icon"
              :title="$t(`modules.process.${currentType}.templates.actions.use`)"
              v-if="userHasPermission('process', 'view_module')"
            />
          </div>

        </el-table>


      </div>

      <el-empty v-else :title="$t(`modules.process.${currentType}.templates.empty_title`)" :text="$t(`modules.process.${currentType}.templates.empty_text`)"></el-empty>

    </auth-content>

</template>

<script>

import store from '@/store'
import Fuse from 'fuse.js'
import _ from 'lodash'

import {
  PROCESS_GET_PROCESSTEMPLATES,
  PROCESS_CREATE_PROCESSTEMPLATE,
  PROCESS_CREATE_PROCESS,

  PROCESS_GET_FORMTEMPLATES,
  PROCESS_CREATE_FORMTEMPLATE,
  PROCESS_CREATE_FORM,
} from '../mutations-types'

export default {
  name: 'ProcessTemplateList',
  data () {
    return {
      processTemplates: false,
      loading: false,
      filters: false,
    }
  },
  mounted () {
  },
  methods: {
    useTemplate (template) {
      this.loading = true
      this.$store.dispatch('process/' + this.mutations.createEntity, {
        worldId: this.$route.params.world,
        data: {
          name: template.name,
          processTemplate: template.id,
          processTemplateContent: [],
        }
      }).then(process => {
        this.loading = false
        this.$router.push({name: this.currentType + '.' + this.currentType + '.page', params: { world: this.$route.params.world, process: process.id}})
      })
      .catch(error => {
        this.loading = false
        this.notifError(error)
      })
    },
    loadFilters () {
      let content = {...this.formatContent()}
      this.filters = Object.assign({}, this.filters, [
        {
          title: this.$t('modules.process.process.templates.filters.owner'),
          key: 'owner',
          options: this.getFilterOptions(content, 'owner'),
        },
        {
          title: this.$t('moduleentity.template.title'),
          key: 'template',
          options: this.getFilterOptions(content, 'template', {'true': this.$t('common.yes')}, this.$t('common.no')),
        },
      ])
    },
    createContent () {
      if (this.loading) {
        return
      }
      this.loading = true
      this.$store.dispatch('process/' + this.mutations.createTemplates, {
        worldId: this.$route.params.world,
        data: {
          name: this.$t(`modules.process.${this.currentType}.templates.new_title`),
          content: [],
          type: this.currentType,
        }
      }).then(template => {
        this.loading = false
        this.$router.push({name: this.currentType + '.template.page', params: { world: this.$route.params.world, template: template.id}})
      })
      .catch(error => {
        this.loading = false
        this.notifError(error)
      })
    },
    formatContent () {
      let contentArray = []

      if (this.processTemplates) {
        // Get processTemplates
        _.map(this.processTemplates, (elem) => {
          let content = {
            id: elem.id,
            // link: this.userHasPermission('process', 'manage_module') ? this.$router.resolve({name: this.currentType + '.template.page', params: { world: this.$route.params.world, template: elem.id } }).href : null,
            owner: elem.author ? elem.author.full_name : '',
            template: elem.template ? elem.template : '',
            name: elem.data.name,
          }
          contentArray.push(content);
        })
      }
      return contentArray
    }
  },
  computed: {
    currentType () {
      return this.$route.path.split('/')[3]
    },
    mutations () {
      let mutations = {
        'process': {
          'getTemplates' : PROCESS_GET_PROCESSTEMPLATES,
          'createTemplates' : PROCESS_CREATE_PROCESSTEMPLATE,
          'createEntity' : PROCESS_CREATE_PROCESS,
        },
        'form': {
          'getTemplates' : PROCESS_GET_FORMTEMPLATES,
          'createTemplates' : PROCESS_CREATE_FORMTEMPLATE,
          'createEntity' : PROCESS_CREATE_FORM,
        },
      }
      return mutations[this.currentType]
    },
    columns () {
      return {
        name: this.$t(`modules.process.${this.currentType}.templates.fields.name`)
      }
    },
    searchValue: {
      get () {
        return this.$store.state.global.sidebarSearch
      },
      set (value) {
        this.$store.commit('global/changeSidebarSearch', value)
      }
    },
    contentFormatted () {
      return this.formatContent()
    },
    contentFiltered () {

      if (!this.filters) {
        return []
      }
      if (this.searchValue === '') {
        return this.applyDataFilters(this.contentFormatted, this.filters)
      }

      return new Fuse(this.applyDataFilters(this.contentFormatted, this.filters), {
        shouldSort: false,
        threshold: 0.3,
        location: 0,
        distance: 100,
        minMatchCharLength: 1,
        keys: [
          "name",
          "category"
        ]
      }).search(this.searchValue)
    },
  },
  beforeRouteEnter (to, from, next) {
    let mutation = to.path.split('/')[3] == 'process' ? PROCESS_GET_PROCESSTEMPLATES : PROCESS_GET_FORMTEMPLATES
    store.dispatch('process/' + mutation, to.params.world).then(processTemplates => {
      next(vm => {
        vm.processTemplates = processTemplates
        vm.loadFilters()
      })
    })
  }
}
</script>

<style lang="scss" scoped>
.card-img-top {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
}
</style>
