<template>

    <auth-content>
      <el-header :title="$t(`modules.process.${currentType}.templates.title`)" slot="header" :favorites="false" />

      <div class="card">
        <div class="card-body p-0">
          <input v-model="processName" type="text" class="border-0 p-3 form-control form-control-title form-control-lg form-control-discreet" @change="process.data.name = processName; updateContent()" />
        </div>
        <div class="card-body border-bottom" v-show="hasContent">
          <div v-if="hasContent">
            <process-entity
              :process="process"
              :classColorName="currentClass"
            />
            <button :class="'my-2 mx-3 px-3 btn btn-' + currentClass + ' btn-lg'" v-if="!hasAutoSave" :disabled="saving" @click="updateContent()">
              {{ $t('common.save') }}
            </button>
          </div>
        </div>

      </div>

      <div slot="sidebar" v-if="process">

        <div class="card" v-if="hasSteps && currentType != 'form'">
          <div class="card-header bg-transparent border-0">
            <i class="icon-book mr-2"></i>
            <span class="card-title font-weight-semibold">{{ $t('modules.process.process.process.summary') }}</span>
          </div>
          <div class="card-body p-0">
            <fragment
              v-for="(contentPart, index) in process.data.processTemplateContent"
            >
              <div class="px-3 py-2 d-flex border-top align-items-center" v-if="contentPart.type === 'step' && contentPart.data != '' && contentPart.data != null">
                <label class="m-0">
                  <input type="checkbox" class="d-none" v-model="process.data.processValues[contentPart.id]" />
                  <i :class="`icon-checkbox-checked2 text-${currentClass} mr-1 cursor-pointer`" v-if="process.data.processValues[contentPart.id]"></i>
                  <i :class="`icon-checkbox-unchecked2 text-${currentClass} mr-1 cursor-pointer`" v-else></i>
                </label>
                <div class="cursor-pointer h5 m-0 py-1" @click="scrollToStep(contentPart.id)">{{ contentPart.data }}</div>
              </div>
            </fragment>
          </div>
        </div>

        <module-entity-informations :saving="saving" :entity="process"></module-entity-informations>
        <!-- <module-entity-permissions :saving="saving" :entity="process" :tribes="process.world.tribes" :users="process.world.users"></module-entity-permissions> -->
      </div>

    </auth-content>

</template>

<script>
import store from '@/store'
import _ from 'lodash'

import {
  PROCESS_GET_PROCESS,
  PROCESS_UPDATE_PROCESS,
} from '../mutations-types'

export default {
  name: 'ProcessPage',
  data () {
    return {
      updateContentTimeout: null,
      loading: true,
      process: false,
      processName: '',
    }
  },
  mounted () {
  },
  methods: {
    scrollToStep (stepId) {
        window.scrollTo({
          top: document.documentElement.scrollTop + document.getElementById('step-' + stepId).getBoundingClientRect().y - 50,
          left: 0,
         behavior: 'smooth'
      })
    },
    updateContent () {
      clearTimeout(this.updateContentTimeout)

      this.loading = true

      this.updateContentTimeout = setTimeout(() => {
        this.$store.dispatch('process/' + PROCESS_UPDATE_PROCESS, {
          worldId: this.$route.params.world,
          processId: this.$route.params.process,
          data: this.process.data,
          // user_id: this.process.user_id,
          // access: this.process.access,
        }).then(process => {
          this.process = process
          setTimeout(() => {
            this.loading = false
          }, 100)
        })
        .catch(error => {
          this.loading = false
          this.notifError(error)
        })
      }, 1000)
    },
  },
  computed: {
    hasAutoSave () {
      return this.currentType == 'process'
    },
    currentType () {
      return this.$route.path.split('/')[3]
    },
    currentClass () {
      return this.currentType == 'form' ? 'success' : 'info'
    },
    hasSteps () {
      if (!this.hasContent) {
        return false
      }
      let hasSteps = false
      this.process.data.processTemplateContent.forEach(contentPart => {
        if (contentPart.type == 'step') {
          hasSteps = true
        }
      })
      return hasSteps
    },
    saving () {
      return this.loading
    },
    hasContent () {
      return this.process && this.process.data && this.process.data.processTemplateContent && this.process.data.processTemplateContent.length > 0
    },
    processValues () {
      if (!this.hasContent) {
        return null
      }
      return {...this.process.data.processValues}
    },
  },
  beforeRouteEnter (to, from, next) {
    store.dispatch('process/' + PROCESS_GET_PROCESS, {
      worldId: to.params.world,
      processId: to.params.process,
    }).then(process => {
      next(vm => {
        vm.loading = false
        vm.process = process
        vm.processName = vm.process.data.name
      })
    })
  },
  watch: {
    'processValues': {
      deep: true,
      handler (newVal, oldVal) {
        if (oldVal !== false) {
          if (!_.isEqual(newVal, oldVal) && this.hasAutoSave) {
            this.updateContent()
          }
        }
      },
    },
  }
}
</script>

<style lang="scss" scoped>
.form-control-title {
  font-size: 1.6em;
}
.d-flex.content {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
</style>
