<template>

    <auth-content
      :forceShowSidebarToggleButton="hasAccessToContent"
    >
      <el-header :title="$t(`modules.process.${currentType}.template.title`)" slot="header" :favorites="false">
        <div slot="left">
          <el-header-back :back="back" />
        </div>
        <div slot="actions" v-if="hasAccessToContent === true">
          <el-header-action
            :disabled="loading"
            size="xs"
            classes="btn-secondary"
            :icon="$router.resolve({name: currentType + '.template.results'}).route.meta.icon"
            :label="$t(`modules.process.${currentType}.templates.actions.results`)"
            @click.native="$router.push({name: currentType + '.template.results', params: { world: $route.params.world, template: $route.params.template } })"
          />
          <el-header-action
            :disabled="loading"
            size="xs"
            classes="btn-success"
            :icon="$router.resolve({name: currentType + '.template.index'}).route.meta.icon"
            :label="$t(`modules.process.${currentType}.templates.actions.use`)"
            @click.native="useTemplate"
          />
        </div>
      </el-header>


      <div v-if="hasAccessToContent === false">
        <module-entity-need-access
          :entityId="parseInt($route.params.template)"
          entityModule="process"
          entityType="ProcessTemplate"
        />
      </div>

      <div class="card" v-if="processTemplate && hasAccessToContent === true">
        <div class="card-body p-0 card-body-title border-bottom bg-white d-flex">
          <input v-model="processTemplateName" type="text" class="border-0 p-3 form-control form-control-title form-control-lg form-control-discreet" @change="processTemplate.data.name = processTemplateName" />
          <div v-if="saving" class="d-flex align-items-center px-3">
            <loader />
            <span class="text-grey ml-1">
              {{ $t('moduleentity.informations.saving') }}
            </span>
          </div>
          <div v-if="justSaved && !saving" class="d-flex align-items-center px-3 font-weight-bold text-success">
            <i class="icon-checkmark-circle mr-1"></i>
            <span>
              {{ $t('common.saved') }}
            </span>
          </div>
        </div>

        <div class="card-body" v-show="contentParts.length > 0">

          <div class="mb-3">
            <label class="font-weight-bold text-dark">{{ $t(`modules.process.${currentType}.template.fields.user`) }}</label>
            <module-form-field-user
              id="user"
              :config="[]"
              :module="modules['process']"
              type="user"
              v-model="processTemplateUser"
              :placeholder="$t(`modules.process.${currentType}.template.fields.user`)"
              mode="edit"
            />
          </div>

          <div v-if="hasContent">
            <div
              v-for="(contentPart, index) in contentParts"
              :key="contentPart.id"
            >
              <div
                @mouseover="contentHover = index"
                @mouseleave="contentHover = false"
                :class="{'bg-light': index === contentHover }"
              >
                <div class="process-addon d-flex justify-content-between align-items-top">
                  <container
                    :id="index"
                    :is="`process-template-addon-${contentPart.type}`"
                    v-model="contentPart.data"
                    :class="{'d-flex content p-0': true, 'pl-3': contentPart.type != 'step'}"
                    :classColorName="currentClass"
                    :entity="processTemplate"
                    :wikiContent="wikiContentFormatted"
                  >
                  </container>
                  <div :class="{'d-flex pt-1 pr-2': true, 'invisible': index !== contentHover}">
                    <i
                      @click="moveContentPartUp(index)"
                      v-show="contentParts.length > 1 && index > 0"
                      :class="{'handle icon-arrow-up7 text-dark py-2 ml-2 cursor-pointer': true, 'text-black': index === contentHover }"
                    />
                    <i
                      @click="moveContentPartDown(index)"
                      v-show="contentParts.length > 1 && index+1 < contentParts.length"
                      :class="{'handle icon-arrow-down7 text-dark py-2 ml-2 cursor-pointer': true, 'text-black': index === contentHover }"
                    />
                    <i
                      :class="{'icon-cross3 text-grey py-2 ml-2 cursor-pointer text-danger': true, 'text-black': index === contentHover }"
                      @dblclick="deleteContentPart(index)"
                      v-tooltip="$t('modules.process.process.template.delete_part')"
                    />
                  </div>
                </div>
              </div>
              <div :class="{
                'separator my-2 position-relative text-center': true,
                'separator-last': index == contentParts.length-1,
              }">
                <i
                  :class="{
                    'cursor-pointer': true,
                    'icon-plus-circle2 open-icon text-success': addContentPartShow != index,
                    'icon-cancel-circle2 close-icon text-danger': addContentPartShow == index,
                  }"
                  @click="addContentPartShow == index ? addContentPartShow = null : addContentPartShow = index"
                  v-tooltip="addContentPartShow == index ? '' : $t(`modules.process.${currentType}.template.title_addons`)"
                />

                <slide-up-down :active="addContentPartShow == index" :duration="300">
                  <div class="addcontentpart-wrapper">
                    <div class="rounded my-1">
                      <div class="py-2">

                        <el-alert v-if="contentParts.length == 0" type="success">{{ $t(`modules.process.${currentType}.template.start_msg`) }}</el-alert>

                        <div class="" v-for="(addOnType, addOnTypeLabel) in addOns" :key="addOnTypeLabel">
                          <div class="text-left px-3 py-1">
                            <div class="px-1 small">{{ addOnTypeLabel }}</div>
                          </div>
                          <div class="px-3 row no-gutters">
                            <div class="col-12 col-lg-6 col-xl-4 col-xxl-3" v-for="addOn in addOnType" :key="addOn.key">
                              <div class="m-1">
                                <span
                                  :class="[{
                                     'btn small-radius w-100 text-left d-flex align-items-center': true,
                                     'cursor-wait': saving
                                  }, 'btn-outline-' + currentClass]"
                                  @click="addContentPart(addOn, index+1)"
                                  v-tooltip="addOn.description"
                                >
                                  <div class="d-inline-block d-lg-block">
                                    <i :class="{'mr-2': true, 'icon-plus2': !addOn.icon, ['icon-' + addOn.icon]: addOn.icon }"></i>
                                  </div>
                                  <span class="text-dark font-weight-bold">{{ addOn.name }}</span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </slide-up-down>

              </div>
            </div>
          </div>
        </div>

        <div class="empty-process p-3" v-if="contentParts.length == 0">

          <el-alert type="success">{{ $t(`modules.process.${currentType}.template.start_msg`) }}</el-alert>

          <div class="addcontentpart-wrapper">
            <div class="rounded my-1">
              <div class="py-2">


                <div class="" v-for="(addOnType, addOnTypeLabel) in addOns" :key="addOnTypeLabel">
                  <div class="text-left px-3 py-1">
                    <div class="px-1 small">{{ addOnTypeLabel }}</div>
                  </div>
                  <div class="px-3 row no-gutters">
                    <div class="col-12 col-lg-6 col-xl-4 col-xxl-3" v-for="addOn in addOnType" :key="addOn.key">
                      <div class="m-1">
                        <span
                          :class="[{
                             'btn small-radius w-100 text-left d-flex align-items-center': true,
                             'cursor-wait': saving
                          }, 'btn-outline-' + currentClass]"
                          @click="addContentPart(addOn)"
                          v-tooltip="addOn.description"
                        >
                          <div class="d-inline-block d-lg-block">
                            <i :class="{'mr-2': true, 'icon-plus2': !addOn.icon, ['icon-' + addOn.icon]: addOn.icon }"></i>
                          </div>
                          <span class="text-dark font-weight-bold">{{ addOn.name }}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>

      <div slot="sidebar" v-if="processTemplate && hasAccessToContent === true">

        <div class="card" v-if="hasSteps">
          <div class="card-header bg-transparent border-0">
            <i class="icon-book mr-2"></i>
            <span class="card-title font-weight-semibold">{{ $t('modules.process.process.process.summary') }}</span>
          </div>
          <div class="card-body p-0">
            <fragment
              v-for="(contentPart, index) in processTemplate.data.content"
            >
              <div class="px-3 py-2 d-flex border-top align-items-center" v-if="contentPart.type === 'step' && contentPart.data != '' && contentPart.data != null">
                <div class="cursor-pointer h5 m-0 py-1" @click="scrollToStep(index)">{{ contentPart.data }}</div>
              </div>
            </fragment>
          </div>
        </div>

        <module-entity-template
          :saving="saving"
          :entity="processTemplate"
          v-if="worldCanManageTemplates"
        />
        <module-entity-permissions :saving="saving" :entity="processTemplate" :tribes="processTemplate.world.tribes" :users="processTemplate.world.users" />
        <module-entity-informations :saving="saving" :entity="processTemplate" />
        <module-entity-public-access
          :saving="saving"
          :entity="processTemplate"
          :publicLink="baseUrl + $router.resolve({name: 'public.form.form.page', params: {world : $route.params.world, publickey : processTemplate.publickey}}).href"
          v-if="currentType == 'form' && isCurrentWorldOwner"
        >
          <div class="small text-grey mb-1">{{ $t('modules.process.form.public.link_description') }}</div>
        </module-entity-public-access>
      </div>

    </auth-content>

</template>

<script>
const NEEDACCESS = 412

import store from '@/store'
import _ from 'lodash'
import moduleFields from '@/components/module/fields'

import {
  WIKI_GET_CONTENTS,
} from '../../Wiki/mutations-types'

import {
  PROCESS_GET_PROCESSTEMPLATE,
  PROCESS_GET_PROCESSTEMPLATES,
  PROCESS_CREATE_PROCESSTEMPLATE,
  PROCESS_UPDATE_PROCESSTEMPLATE,
  PROCESS_CREATE_PROCESS,

  PROCESS_GET_FORMTEMPLATE,
  PROCESS_GET_FORMTEMPLATES,
  PROCESS_CREATE_FORMTEMPLATE,
  PROCESS_UPDATE_FORMTEMPLATE,
  PROCESS_CREATE_FORM,
} from '../mutations-types'

import {
  MODULE_REFRESH_PUBLICKEY,
  MODULE_CANCEL_PUBLICKEY,
} from '@/store/mutations-types'

export default {
  name: 'ProcessTemplate',
  components: {
    'process-template-addon-textarea': require('../components/addons/Textarea.vue').default,
    'process-template-addon-textfield': require('../components/addons/Textfield.vue').default,
    'process-template-addon-editor': require('../components/addons/Editor.vue').default,
    'process-template-addon-checklist': require('../components/addons/Checklist.vue').default,
    'process-template-addon-selectlist': require('../components/addons/Selectlist.vue').default,
    'process-template-addon-step': require('../components/addons/Step.vue').default,
    'process-template-addon-image': require('../components/addons/Image.vue').default,
    'process-template-addon-action': require('../components/addons/Action.vue').default,
    'process-template-addon-wikilink': require('../components/addons/WikiLink.vue').default,
    'process-template-addon-rating': require('../components/addons/Rating.vue').default,
    ...moduleFields
  },
  data () {
    return {
      hasAccessToContent: null,
      loading: true,
      wikiContent: [],
      contentHover: false,
      processTemplate: false,
      processTemplateName: '',
      processTemplateUser: null,
      contentPartAdded: false,
      justSaved: false,
      willSave: false,
      filters: false,
      addContentPartShow: null,
    }
  },
  mounted () {
    if (this.modules['wiki'].active) {
      this.loadWikiContent()
    }
  },
  methods: {
    useTemplate () {
      this.loading = true
      this.$store.dispatch('process/' + this.mutations.createEntity, {
        worldId: this.$route.params.world,
        data: {
          name: this.processTemplate.data.name,
          processTemplate: this.processTemplate.id,
          processTemplateContent: [],
        }
      }).then(process => {
        this.loading = false
        this.$router.push({name: this.currentType + '.' + this.currentType + '.page', params: { world: this.$route.params.world, process: process.id}})
      })
      .catch(error => {
        this.loading = false
        this.notifError(error)
      })
    },
    scrollToStep (stepId) {
        window.scrollTo({
          top: document.documentElement.scrollTop + document.getElementById('step-' + stepId).getBoundingClientRect().y - 50,
          left: 0,
         behavior: 'smooth'
      })
    },
    back () {
      this.$router.push({ name:this.currentType + '.template.index', params: { world: this.$route.params.world } })
    },
    loadWikiContent () {
      this.$store.dispatch('wiki/' + WIKI_GET_CONTENTS, this.$route.params.world).then(content => {
        this.wikiContent = content
      })
    },
    getNextContentPartId () {
      if (!this.processTemplate.data.content || this.processTemplate.data.content.length == 0) {
        return 0
      } else {
        let maxId = 0
        this.processTemplate.data.content.forEach( contentPart => {
          if (contentPart.id >= maxId) {
            maxId = contentPart.id + 1
          }
        })
        return maxId
      }
    },
    addContentPart (addOn, index = 0) {
      this.contentPartAdded = true
      if (!this.processTemplate.data.content) {
        this.$set(this.processTemplate.data, 'content', [])
      }
      this.processTemplate.data.content.splice(index, 0, {
        id: this.getNextContentPartId(),
        type: addOn.key,
        data: addOn.default,
      })
      this.addContentPartShow = null
    },
    updateContentDebounce: _.debounce(function() {
      this.updateContent();
    }, 2000),
    updateContent () {

      if (! this.$route.params.template) {
        return
      }

      this.loading = true
      let mutation = this.$route.path.split('/')[3] == 'process' ? PROCESS_UPDATE_PROCESSTEMPLATE : PROCESS_UPDATE_FORMTEMPLATE

      this.$store.dispatch('process/' + mutation, {
        worldId: this.$route.params.world,
        templateId: this.$route.params.template,
        data: this.processTemplate.data,
        user_id: this.processTemplate.user_id,
        access: this.processTemplate.access,
        access_all: this.processTemplate.access_all,
      }).then(processTemplate => {
        this.processTemplate.created_at = processTemplate.created_at
        this.processTemplate.updated_at = processTemplate.updated_at
        setTimeout(() => {
          this.loading = false
          this.willSave = false
        }, 100)
      })
      .catch(error => {
        this.loading = false
        this.willSave = false
        this.notifError(error)
      })
    },
    deleteContentPart (index) {
      this.processTemplate.data.content.splice(index, 1)
    },
    moveContentPartUp (old_index) {
      let new_index = old_index - 1
      if (new_index >= this.processTemplate.data.content.length) {
        var k = new_index - this.processTemplate.data.content.length + 1;
        while (k--) {
          this.processTemplate.data.content.push(undefined);
        }
      }
      this.processTemplate.data.content.splice(new_index, 0, this.processTemplate.data.content.splice(old_index, 1)[0]);
    },
    moveContentPartDown (old_index) {
      let new_index = old_index + 1
      if (new_index >= this.processTemplate.data.content.length) {
        var k = new_index - this.processTemplate.data.content.length + 1;
        while (k--) {
          this.processTemplate.data.content.push(undefined);
        }
      }
      this.processTemplate.data.content.splice(new_index, 0, this.processTemplate.data.content.splice(old_index, 1)[0]);
    },
  },
  computed: {
    addOns () {

      let addOns = {
        [this.$t('modules.process.process.template.addon_types.markup')]: [
          {
            key: 'step',
            name: this.$t('modules.process.process.template.addons.step.name'),
            description: this.$t('modules.process.process.template.addons.step.description'),
            default: '',
            icon: 'arrow-right8',
          },
          {
            key: 'editor',
            name: this.$t('modules.process.process.template.addons.editor.name'),
            description: this.$t('modules.process.process.template.addons.editor.description'),
            default: '',
            icon: 'typography',
          },
          // @TODO : Fix image issue
          // {
          //   key: 'image',
          //   name: this.$t('modules.process.process.template.addons.image.name'),
          //   description: this.$t('modules.process.process.template.addons.image.description'),
          //   default: '',
          //   icon: 'image2',
          // },
        ],
        [this.$t('modules.process.process.template.addon_types.fields')]: [
          {
            key: 'checklist',
            name: this.$t('modules.process.process.template.addons.checklist.name'),
            description: this.$t('modules.process.process.template.addons.checklist.description'),
            default: [''],
            icon: 'checkbox-checked2',
          },
          {
            key: 'textfield',
            name: this.$t('modules.process.process.template.addons.textfield.name'),
            description: this.$t('modules.process.process.template.addons.textfield.description'),
            default: '',
            icon: 'keyboard',
          },
          {
            key: 'textarea',
            name: this.$t('modules.process.process.template.addons.textarea.name'),
            description: this.$t('modules.process.process.template.addons.textarea.description'),
            default: '',
            icon: 'keyboard',
          },
          {
            key: 'selectlist',
            name: this.$t('modules.process.process.template.addons.selectlist.name'),
            description: this.$t('modules.process.process.template.addons.selectlist.description'),
            default: '',
            icon: 'height2',
          },
          {
            key: 'rating',
            name: this.$t('modules.process.process.template.addons.rating.name'),
            description: this.$t('modules.process.process.template.addons.rating.description'),
            default: [''],
            icon: 'star-full2',
          },
        ],
      }

      if (this.modules['wiki'].active) {
        addOns[this.$t('modules.process.process.template.addon_types.markup')].push({
          key: 'wikilink',
          name: this.$t('modules.process.process.template.addons.wikilink.name'),
          description: this.$t('modules.process.process.template.addons.wikilink.description'),
          default: [''],
          icon: 'book',
        })
      }
      if (this.modules['actionsboard'].active) {
        addOns[this.$t('modules.process.process.template.addon_types.fields')].push({
          key: 'action',
          name: this.$t('modules.process.process.template.addons.action.name'),
          description: this.$t('modules.process.process.template.addons.action.description'),
          default: [''],
          icon: 'user-check',
        })
      }

      return addOns
    },
    modules () {
      return store.getters['world/getModules'];
    },
    mutations () {
      let mutations = {
        'process': {
          'getTemplates' : PROCESS_GET_PROCESSTEMPLATES,
          'getTemplate' : PROCESS_GET_PROCESSTEMPLATE,
          'createTemplates' : PROCESS_CREATE_PROCESSTEMPLATE,
          'createEntity' : PROCESS_CREATE_PROCESS,
        },
        'form': {
          'getTemplates' : PROCESS_GET_FORMTEMPLATES,
          'getTemplate' : PROCESS_GET_FORMTEMPLATE,
          'createTemplates' : PROCESS_CREATE_FORMTEMPLATE,
          'createEntity' : PROCESS_CREATE_FORM,
        },
      }
      return mutations[this.currentType]
    },
    baseUrl () {
      return `${window.location.protocol}//${window.location.host}`
    },
    isCurrentWorldOwner () {
      return this.$store.getters['auth/isWorldOwner'];
    },
    worldCanManageTemplates () {
      return this.$store.getters['auth/worldCanManageTemplates'];
    },
    currentType () {
      return this.$route.path.split('/')[3]
    },
    currentClass () {
      return this.currentType == 'form' ? 'success' : 'info'
    },
    hasSteps () {
      if (!this.hasContent) {
        return false
      }
      let hasSteps = false
      this.processTemplate.data.content.forEach(contentPart => {
        if (contentPart.type == 'step') {
          hasSteps = true
        }
      })
      return hasSteps
    },
    saving () {
      return this.loading
    },
    hasContent () {
      return this.processTemplate && this.processTemplate.data && this.processTemplate.data.content && this.processTemplate.data.content.length > 0
    },
    contentParts: {
        get () {
          return this.processTemplate.data.content
        },
        set (value) {
          this.processTemplate.data.content = value
        }
    },
    wikiContentFormatted () {
      return this.wikiContent.map(content => {
        return {
          value: content.id,
          title: content.name,
          detail: content.category_id ? this.$t('modules.quest.categories.' + content.category.name) : '',
        }
      })
    }
  },
  beforeRouteEnter (to, from, next) {
    let mutation = to.path.split('/')[3] == 'process' ? PROCESS_GET_PROCESSTEMPLATE : PROCESS_GET_FORMTEMPLATE
    store.dispatch('process/' + mutation, {
      worldId: to.params.world,
      templateId: to.params.template,
    }).then(processTemplate => {
      next(vm => {
        vm.hasAccessToContent = true
        vm.loading = false
        vm.processTemplate = processTemplate
        vm.processTemplateName = vm.processTemplate.data.name
        vm.processTemplateUser = vm.processTemplate.data.user
      })
    }).catch(error => {
      const {status} = error.response
      if (status === NEEDACCESS) {
        store.commit('global/changeSidebarRight', false)
        next(vm => {
          vm.hasAccessToContent = false
        })

      }
    })
  },
  beforeRouteLeave (to, from, next) {
    this.updateContent()
    next()
  },
  watch: {
    processTemplateUser (val) {
      this.processTemplate.data.user = val
      if (!this.saving) {
        this.willSave = true
        this.updateContentDebounce()
      }
    },
    processTemplate: {
      deep: true,
      handler (newVal, oldVal) {
        if (oldVal !== false && !this.saving && !this.contentPartAdded) {
          this.willSave = true
          this.updateContentDebounce()
        }
        this.contentPartAdded = false
      },
    },
    saving (val) {
      if (val === false) {
        this.justSaved = true
        setTimeout(() => {
          this.justSaved = false
        }, 2000)
      } else {
        this.justSaved = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.process-addon {
  min-height: 42px;
}
.form-control-title {
  font-size: 1.6em;
}
.d-flex.content {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.card-body-title {
  position: sticky;
  top: 52px;
  z-index: 2;
}
/deep/ .separator {
  i {
    position: relative;
    z-index: 3;
    transition: all .2s ease;
    &.open-icon {
      visibility: hidden;
    }
    &.close-icon {
      margin-bottom: -30px;
    }
  }
  &.separator-last,
  &:hover {
    .open-icon {
      visibility: visible;
    }
  }
  .separator-line {
    background: #FAFAFA;
    height: 4px;
  }
}
.addcontentpart-wrapper > div{
  background: rgba(#00BCD4, .08);
}
</style>
